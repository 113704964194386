import React, { useMemo } from 'react'

import ManaFrontIcon, { ManaFontIcons } from 'components/misc/customIcons/ManaFont'

import { orderColorsToOfficialOrder } from 'utils/colorUtils'

import { COLORSYMBOL_OBJECT } from '../charts.const'

import styles from './colorBar.module.scss'

type Props = {
  colors: Record<string, number>
  className?: string
  includeManaSymbols?: boolean
}

/**
 * This is the component that makes a pretty color bar when looking at the deck cards
 * (The deck card is the thing on the homepage that you click on to view a deck)
 */
const ColorBar = ({ colors, className = '', includeManaSymbols = false }: Props) => {
  const colorPercentages = useMemo(() => {
    const orderdColors = orderColorsToOfficialOrder(colors)

    let sum = 0
    let percentages = []

    for (const obj of orderdColors) {
      sum += obj.quantity
    }

    for (const obj of orderdColors) {
      if (obj.quantity > 0) percentages.push({ name: obj.color, percent: (obj.quantity / sum) * 100 })
    }

    if (sum === 0) return [{ name: 'C', percent: 100 }]

    return percentages
  }, [colors])

  return (
    <div className={`${styles.bar} ${className}`}>
      {colorPercentages.map((color, i) => {
        let barClass = styles.middle

        const { percent, name } = color
        const backgroundColor = COLORSYMBOL_OBJECT[name]
        const style: React.CSSProperties = { backgroundColor, width: `${percent}%` }

        let manaSymbol: ManaFontIcons = 'w'

        if (name === 'W') manaSymbol = 'w'
        if (name === 'U') manaSymbol = 'u'
        if (name === 'B') manaSymbol = 'b'
        if (name === 'R') manaSymbol = 'r'
        if (name === 'G') manaSymbol = 'g'
        if (name === 'C') manaSymbol = 'c'

        if (colorPercentages.length === 1) barClass = styles.solid
        else if (i === 0) barClass = styles.left
        else if (i === colorPercentages.length - 1) barClass = styles.right

        return (
          <div key={i} style={style} className={barClass}>
            {includeManaSymbols && (
              <ManaFrontIcon style={{ color: backgroundColor, filter: 'brightness(.2)' }} icon={manaSymbol} />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default ColorBar
